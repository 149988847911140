









import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

import ServiceComponent from "@/components/Service.vue";
import TechStackComponent from "@/components/TechStack.vue";
import ProductComponent from "@/components/Product.vue";
import TeamComponent from "@/components/Team.vue";

@Component({
  components: {
    ServiceComponent,
    TechStackComponent,
    ProductComponent,
    TeamComponent,
  },
})
export default class Landing extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("landingLayout")
  private landingLayout: any;

  /* STATE */

  /* INITIALIZATION */
  private created() {
    this.landingLayout();
  }

  /* METHODS */
}

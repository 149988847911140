

































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import { State } from "vuex-class";

import NavbarComponent from "@/components/Navbar.vue";
import FooterComponent from "@/components/Footer.vue";

@Component({
  components: {
    NavbarComponent,
    FooterComponent,
  },
})
export default class AppLayout extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */
  @State("locale")
  private locale!: string;

  /* INITIALIZATION */
  private mounted() {
    $(".preloader").hide();
  }

  /* METHODS */
}

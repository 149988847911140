











































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class TechStack extends Vue {
  /* VUEX */
  /* ACTIONS */
  /* STATE */
  /* INITIALIZATION */
  private array_tech = [
    { image: require("@/assets/images/techstack/flutter.png") },
    { image: require("@/assets/images/techstack/figma.png") },
    { image: require("@/assets/images/techstack/hapi.png") },
    { image: require("@/assets/images/techstack/kotlin.png") },
    { image: require("@/assets/images/techstack/laravel.png") },
    { image: require("@/assets/images/techstack/react.png") },
    { image: require("@/assets/images/techstack/vue.png") },
    { image: require("@/assets/images/techstack/xcode.png") },
  ];
  /* METHODS */
}

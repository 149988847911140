import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";

import { actions } from "@/store/actions";
import { mutations } from "@/store/mutations";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {},
  actions,
  mutations,
  state: {
    layout: "",
    locale: "en",
  },
};

export default new Vuex.Store<RootState>(store);

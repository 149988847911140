












































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Action, State } from "vuex-class";

@Component
export default class App extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("changeLocale")
  private changeLocale: any;

  /* STATE */
  @State("locale")
  private locale!: string;

  @Prop({ default: false })
  private isDark!: boolean;
  @Prop({ default: false })
  private isLanding!: boolean;

  /* INITIALIZATION */

  /* METHODS */
  private changeLang(lang: string) {
    if (this.locale !== lang) {
      this.changeLocale(lang);
    }
  }

  private clickMenu() {
    const { name } = this.$route;
    if (name !== "landing") {
      this.$router.push({ name: "landing" }, () => {
        location.reload();
      });
    }
  }
}

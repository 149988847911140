




import Vue from "vue";
import moment from "moment";
import Component from "vue-class-component";
import { Action, State } from "vuex-class";

import LandingLayout from "@/layouts/LandingLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";

@Component({
  components: {
    LandingLayout,
    AppLayout,
  },
})
export default class App extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("landingLayout")
  private landingLayout: any;

  /* STATE */
  @State("locale")
  private locale!: string;
  @State("layout")
  private layout!: string;

  /* INITIALIZATION */
  private created() {
    this.$i18n.locale = this.locale;
    moment.locale(this.locale);

    this.landingLayout();
  }

  /* METHODS */
}

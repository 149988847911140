












































































































import Vue from "vue";
import { Action } from "vuex-class";
import Component from "vue-class-component";

import NavbarComponent from "@/components/Navbar.vue";

@Component({
  components: {
    NavbarComponent,
  },
})
export default class PrivacyPolicy extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;

  /* STATE */

  /* INITIALIZATION */
  private created() {
    this.appLayout();
  }

  /* METHODS */
}

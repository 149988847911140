














































































































































































































import i18n from "@/i18n";
import $ from "jquery";
import Swal2 from "sweetalert2";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Footer extends Vue {
  /* VUEX */

  /* ACTIONS */

  /* STATE */

  /* INITIALIZATION */
  private contact = {
    name: "",
    email: "",
    message: "",
  };

  /* METHODS */
  private getYear() {
    return new Date().getFullYear();
  }

  private isLanding() {
    const { name } = this.$route;

    return name === "landing";
  }

  private sendMessage() {
    this.contact = {
      name: "",
      email: "",
      message: "",
    };
    Swal2.fire({
      title: `${i18n.t("success")}!`,
      text: `${i18n.t("send_message_success")}`,
      icon: "success",
      confirmButtonText: `${i18n.t("okay")}`,
    });
  }

  private checkMenuActive(menu: string[]): boolean {
    const { name } = this.$route;
    if (menu) {
      return menu.includes(String(name));
    }
    return false;
  }

  private clickMenu(menu: string) {
    const { name } = this.$route;
    if (name !== menu) {
      $(document).ready(function () {
        // eslint-disable-next-line no-undef
        const jQ = jQuery;

        jQ("html, body").animate({ scrollTop: 0 }, 800, "easeInOutExpo");
      });
      this.$router.push({ name: menu });
    }
  }
}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Landing from "@/views/Landing.vue";
import Blog from "@/views/pages/Blog.vue";
import Career from "@/views/pages/Career.vue";
import Investor from "@/views/pages/Investor.vue";
import PrivacyPolicy from "@/views/pages/PrivacyPolicy.vue";
import TermsOfService from "@/views/pages/TermsOfService.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/career",
    name: "career",
    component: Career,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: TermsOfService,
  },
  {
    path: "/investor-relations",
    name: "investor",
    component: Investor,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;





































































































































import Vue from "vue";
import i18n from "@/i18n";
import Swal2 from "sweetalert2";
import Component from "vue-class-component";
import { Action } from "vuex-class";

import NavbarComponent from "@/components/Navbar.vue";

@Component({
  components: {
    NavbarComponent,
  },
})
export default class Career extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("appLayout")
  private appLayout: any;

  /* STATE */

  /* INITIALIZATION */
  private created() {
    this.appLayout();
  }

  /* METHODS */
  private apply(job: number) {
    switch (job) {
      case 1:
        Swal2.fire({
          title: "Oops...",
          text: `${i18n.t("job_failed_apply")}`,
          icon: "error",
          confirmButtonText: `${i18n.t("okay")}`,
        });
        break;
      default:
        Swal2.fire({
          title: "Oops...",
          text: `${i18n.t("job_failed_apply")}`,
          icon: "error",
          confirmButtonText: `${i18n.t("okay")}`,
        });
        break;
    }
  }
}
